import { Box, Flex, FormLabel, Text, useDisclosure } from '@chakra-ui/react'
import { ENUM_STAGE_FAILURE_TAG } from '__generated__/globalTypes'
import { FaPencilAlt, FaPlus } from 'react-icons/fa'

import { FC, useState } from 'react'

import Button from '~/components/ui/Button'
import { FailureTagsOptions } from '~/constants'
import { PersonDetails_person } from '~/inputs/__generated__/PersonDetails'
import FailedReasonView, { FailureReason } from '~/views/failedReason'
import SgOptionsForm from '~/views/sgOptionsForm'

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	personData: PersonDetails_person | null | undefined
	stageId?: string
	failureTag?: ENUM_STAGE_FAILURE_TAG | null
	failureNotes?: string | null
	editMode?: boolean
	onAdd?: (param: FailureReason) => void
}

/***
 *
 * Failed Reason Input Component
 *
 ***/
const FailedReasonInput: FC<Props> = props => {
	const { stageId, failureTag, failureNotes, editMode, onAdd, personData } = props
	const { isOpen, onClose, onOpen } = useDisclosure()

	const [addedReason, setAddedReason] = useState<FailureReason>({
		failure_tag: failureTag,
		failure_notes: failureNotes,
	})

	const failureReason = editMode
		? { failure_tag: failureTag, failure_notes: failureNotes }
		: addedReason

	return (
		<>
			<Box>
				<Flex align='center' justify='space-between'>
					<FormLabel fontSize='1em' color='gray.600' fontWeight='bold'>
						Failed reason
					</FormLabel>
					<Button
						title={failureTag ? 'Edit' : 'Add'}
						aria-label={failureTag ? 'Edit' : 'Add'}
						colorScheme='green'
						size='sm'
						leftIcon={failureReason.failure_tag ? <FaPencilAlt /> : <FaPlus />}
						onClick={onOpen}
					/>
				</Flex>

				<Box>
					<Text fontSize='sm' color='gray.500' mb='0.5em'>
						Tags
					</Text>
					<Box w='20em' bg='gray.100' p='0.8em' rounded='md' mb='1em'>
						<Text fontSize='0.8em' color='gray.600'>
							{failureReason.failure_tag
								? FailureTagsOptions.find(opt => opt.value === failureReason.failure_tag)?.label
								: 'No tag selected'}
						</Text>
					</Box>
				</Box>

				<Box>
					<Text fontSize='sm' color='gray.500' mb='0.5em'>
						Notes
					</Text>
					<Box w='20em' bg='gray.100' p='0.8em' rounded='md'>
						<Text fontSize='0.8em' color='gray.600'>
							{failureReason.failure_notes ? failureReason.failure_notes : 'No notes added'}
						</Text>
					</Box>
				</Box>
				<Box sx={{ marginTop: '10px' }}>
					<SgOptionsForm personData={personData} />
				</Box>
			</Box>

			{isOpen && (
				<FailedReasonView
					stageId={stageId}
					personData={personData}
					editMode={editMode}
					isOpen={isOpen}
					onClose={onClose}
					failureTag={failureReason.failure_tag}
					failureNotes={failureReason.failure_notes}
					onAdd={reason => {
						setAddedReason(reason)
						onAdd?.(reason)
						onClose()
					}}
				/>
			)}
		</>
	)
}

export default FailedReasonInput
