import { gql, useMutation } from '@apollo/client'
import { Box, Button, Checkbox, FormControl, FormLabel, Heading, Spinner } from '@chakra-ui/react'

import React, { useEffect } from 'react'

import { onError } from '~/components/helpers'
import useToast from '~/components/ui/Toast'
import { PersonDetails_person } from '~/inputs/__generated__/PersonDetails'
import {
	FindSendGridContact,
	FindSendGridContactVariables,
} from '~/views/__generated__/FindSendGridContact'
import {
	UpdateOrCreateContactInSendGrid,
	UpdateOrCreateContactInSendGridVariables,
} from '~/views/__generated__/UpdateOrCreateContactInSendGrid'

export const findSendGridContactMutation = gql`
	mutation FindSendGridContact($data: findSendGridContactInput!) {
		findSendGridContact(data: $data) {
			id
			custom_fields
		}
	}
`

export const updateOrCreateContactInSendGridMutation = gql`
	mutation UpdateOrCreateContactInSendGrid($data: updateOrCreateContactInSendGridInput!) {
		updateOrCreateContactInSendGrid(data: $data) {
			message
		}
	}
`

const options = [
	{
		title: 'Invisalign',
		value: 'invisalign',
	},
	{
		title: 'Offers',
		value: 'offers',
	},
	{
		title: 'Implants',
		value: 'implants',
	},
	{
		title: 'Cosmetic',
		value: 'cosmetic',
	},
	{
		title: 'Oral Health',
		value: 'oral_health',
	},
	{
		title: 'General',
		value: 'general',
	},
	{
		title: 'Facial Aesthetics',
		value: 'facial_aesthetics',
	},
	{
		title: 'Opt Out',
		value: 'opt_out',
	},
]

interface SgOptionsFormProps {
	editMode?: boolean
	personData: PersonDetails_person | null | undefined
}

const SgOptionsForm = ({ editMode, personData }: SgOptionsFormProps) => {
	const toast = useToast()

	const [pickedOptions, setPickedOptions] = React.useState<string[]>([])

	const [findSGContact, { loading: contactLoading }] = useMutation<
		FindSendGridContact,
		FindSendGridContactVariables
	>(findSendGridContactMutation, {
		onError: error => onError(error, toast),
	})

	const [updateOrCreateSGContact, { loading: updateLoading }] = useMutation<
		UpdateOrCreateContactInSendGrid,
		UpdateOrCreateContactInSendGridVariables
	>(updateOrCreateContactInSendGridMutation, {
		onError: error => onError(error, toast),
	})

	useEffect(() => {
		if (personData) {
			const requestData = personData.sendgrid_contact_id
				? { sendgrid_contact_id: personData.sendgrid_contact_id }
				: { email: personData.email }

			findSGContact({
				variables: {
					data: requestData,
				},
			}).then(res => {
				if (res.data?.findSendGridContact) {
					const customFields = res.data.findSendGridContact.custom_fields
					const pickedOptions = options.reduce((acc, option) => {
						if (customFields[option.value] === 'true') {
							acc.push(option.value)
						}
						return acc
					}, [] as string[])
					setPickedOptions(pickedOptions)
				}
			})
		}
	}, [])

	const handleChangeCheckboxes = async () => {
		const data = {
			person: {
				id: personData?.id,
				email: personData?.email,
				first_name: personData?.first_name,
				last_name: personData?.last_name,
			},
			// send all options, but opt: true should be only for picked, false for not picked
			// Should be like { general: true, implants: false, ... }
			custom_fields: options.reduce((acc, option) => {
				acc[option.value] = pickedOptions.includes(option.value).toString()
				return acc
			}, {}),
		}
		await updateOrCreateSGContact({
			variables: {
				data,
			},
		})
	}

	return (
		<Box
			sx={{
				border: '1px solid #eee',
				borderRadius: '8px',
				padding: '10px 5px',
				position: 'relative',
			}}
		>
			{updateLoading || contactLoading ? (
				<Box
					sx={{
						background: 'rgba(255,255,255,0.6)',
						zIndex: 10,
						width: '100%',
						height: '100%',
						display: 'flex',
						position: 'absolute',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner color='#000' />
				</Box>
			) : null}
			<Heading color='#000' size='md'>
				Sendgrid email options
			</Heading>
			<Box sx={{ marginTop: '10px' }}>
				{options.map(option => (
					<Box key={option.value} sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
						<FormControl display='flex' alignItems='center'>
							<Checkbox
								isDisabled={editMode}
								isChecked={pickedOptions.includes(option.value)}
								colorScheme='green'
								size='lg'
								onChange={evt =>
									setPickedOptions(
										evt.target.checked
											? [...pickedOptions, option.value]
											: pickedOptions.filter(pickedOption => pickedOption !== option.value)
									)
								}
							/>
							<FormLabel mb='0' color='gray.600' fontWeight='bold' ml={2}>
								{option.title}
							</FormLabel>
						</FormControl>
					</Box>
				))}
			</Box>
			<Button sx={{ marginTop: '10px' }} onClick={handleChangeCheckboxes}>
				Save
			</Button>
		</Box>
	)
}

export default SgOptionsForm
