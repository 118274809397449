import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Stack,
	Skeleton,
	ModalFooter,
	Button,
	Text,
	Flex,
	Grid,
	GridItem,
	Box,
} from '@chakra-ui/react'

import { FC, useContext, useEffect, useState } from 'react'

import { createTransactionMutation } from '~/components/forms/BalanceForm'
import {
	CreateTransaction,
	CreateTransactionVariables,
} from '~/components/forms/__generated__/CreateTransaction'
import { onError } from '~/components/helpers'
import { AuthContext } from '~/components/page/context'
import useToast from '~/components/ui/Toast'
import { StageStatusValues, StageTypeReqFields } from '~/constants'
import { PersonDetails, PersonDetailsVariables } from '~/inputs/__generated__/PersonDetails'
import FailedReasonInput from '~/inputs/failedReason'
import { personDetailQuery } from '~/inputs/person'

import { ENUM_STAGE_TYPE, ENUM_TRANSACTION_TYPE, StageInput } from '../../__generated__/globalTypes'
import { LastDepositAmount, LastDepositAmountVariables } from './__generated__/LastDepositAmount'
import { LatestTXPlan, LatestTXPlanVariables } from './__generated__/LatestTXPlan'
import { StageDetails, StageDetailsVariables } from './__generated__/StageDetails'

/***
 *
 * Query & Mutations
 *
 ***/
const stageDetailQuery = gql`
	query StageDetails($id: ID!) {
		stage(id: $id) {
			id
			type
			consented
			failure_tag
			failure_notes
			note {
				id
				text
			}
			inquiries {
				id
				name
			}
			payment {
				id
			}
			assignees {
				id
			}
			tx_plan {
				id
			}
			origin {
				person {
					id
				}
			}
		}
	}
`
const latestTXPlanQuery = gql`
	query LatestTXPlan($leadId: ID!) {
		latestTxPlan(leadId: $leadId) {
			id
			cost
			tx_plan {
				link
			}
		}
	}
`
const lastDepositQuery = gql`
	query LastDepositAmount($where: JSON) {
		transactions(where: $where) {
			id
			amount
			type
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	leadId: string
	txPlanId: string | null
	personId: string
	editMode?: boolean
	consented?: boolean
	isOpen: boolean
	onClose: () => void
	onSubmit: StageOnSubmit
	onRefetch?: () => Promise<void>
	leadsCurrentStageInfo?: {
		id: string
		type: ENUM_STAGE_TYPE
	}
	viewStageInfo: {
		id: string
		type: ENUM_STAGE_TYPE
	}
	transitionStageInfo?: {
		type: ENUM_STAGE_TYPE
	}
}

export type StageFieldVals = Omit<StageInput, 'type'>

export type StageOnSubmit = (fields: StageFieldVals) => Promise<StageSubmitValue>

type StageSubmitValue = {
	stageId?: string | undefined
}

/***
 *
 * User View Component
 *
 ***/
const StageView: FC<Props> = props => {
	const {
		isOpen,
		onClose,
		onSubmit,
		consented,
		leadId,
		personId,
		editMode,
		viewStageInfo,
		txPlanId,
		transitionStageInfo,
	} = props
	const toast = useToast()
	const user = useContext(AuthContext)

	const [fetchViewStageDetails, viewStageDetailsResult] = useLazyQuery<
		StageDetails,
		StageDetailsVariables
	>(stageDetailQuery, {
		fetchPolicy: 'network-only',
	})

	const latestTxPlanResult = useQuery<LatestTXPlan, LatestTXPlanVariables>(latestTXPlanQuery, {
		variables: { leadId },
		fetchPolicy: 'network-only',
	})

	const [fetchLastDeposit, lastDepositResult] = useLazyQuery<
		LastDepositAmount,
		LastDepositAmountVariables
	>(lastDepositQuery)

	const { data: personDetails, loading: personDetailsLoading } = useQuery<
		PersonDetails,
		PersonDetailsVariables
	>(personDetailQuery, {
		variables: { personId },
	})

	const [createTransaction] = useMutation<CreateTransaction, CreateTransactionVariables>(
		createTransactionMutation,
		{
			onError: error => onError(error, toast),
			refetchQueries: ['PersonDetails', 'ActiveTXPlan'],
		}
	)

	const [stageFieldVals, setStageFieldVals] = useState<StageFieldVals>({ consented })
	const [isSubmitting, setIsSubmitting] = useState(false)

	const stageType = (editMode ? viewStageInfo.type : transitionStageInfo?.type) as ENUM_STAGE_TYPE
	const lastDepositAmount =
		(lastDepositResult.data?.transactions?.length &&
			lastDepositResult.data?.transactions[lastDepositResult.data?.transactions.length - 1]
				?.amount) ||
		0

	const editStageTitle = viewStageDetailsResult.loading ? (
		<Stack w='15em'>
			<Skeleton height='1.5em' rounded='md' />
		</Stack>
	) : (
		<Flex gap={1} align='center'>
			Edit
			<Text color='orange.500'>{StageStatusValues[stageType]}</Text>
			stage
		</Flex>
	)

	const moveStageTitle = viewStageDetailsResult.loading ? (
		<Stack w='15em'>
			<Skeleton height='1.5em' rounded='md' />
		</Stack>
	) : (
		<Flex gap={2} align='center'>
			<Text color='gray.500'>{StageStatusValues[viewStageInfo.type]}</Text>
			<ArrowForwardIcon />
			<Text color='green.600'>{StageStatusValues[stageType]}</Text>
		</Flex>
	)

	const submitHandler = async () => {
		if (!transitionStageInfo?.type) return

		const requriedFields = StageTypeReqFields[transitionStageInfo.type] as (keyof StageFieldVals)[]
		const stageFieldKeys = (Object.keys(stageFieldVals) as (keyof StageFieldVals)[]).filter(
			key => stageFieldVals[key] !== undefined
		)

		const submitAllowed = requriedFields.every(field => stageFieldKeys.includes(field))

		if (!submitAllowed) {
			const emptyFields = requriedFields.filter(key => !stageFieldKeys.includes(key))

			toast({
				title: `${emptyFields.join(', ')} ${emptyFields.length > 1 ? 'are' : 'is'} requried.`,
				status: 'error',
				position: 'top-right',
			})

			return
		}

		setIsSubmitting(true)

		const { stageId } = await onSubmit(stageFieldVals)

		const transactionInputData = {
			owner: user.id,
			origin: personId,
			linked_tx_plan: latestTxPlanResult.data?.latestTxPlan?.id,
			linked_stage: stageId,
		}

		// // When moving to appointment attended stage, deduct the balance tx plan cost from patient's balance
		if (
			transitionStageInfo.type === ENUM_STAGE_TYPE.appointment_attended &&
			lastDepositResult.data?.transactions?.length
		) {
			const latestTxPlanCost = latestTxPlanResult.data?.latestTxPlan?.cost || 0
			const amountDue = latestTxPlanCost - lastDepositAmount

			await createTransaction({
				variables: {
					input: {
						data: {
							...transactionInputData,
							amount: amountDue,
							type: ENUM_TRANSACTION_TYPE.payment,
							leadId,
						},
					},
				},
			})
		}
		setIsSubmitting(false)
		onClose()
	}

	// Fetch stage details for stage view when in edit mode.
	useEffect(() => {
		editMode && viewStageInfo.id && fetchViewStageDetails({ variables: { id: viewStageInfo.id } })
	}, [viewStageInfo.id])

	// Fetch last deposit amount for person when transitioning to appointment attended.
	useEffect(() => {
		const fetchAllowed =
			transitionStageInfo?.type == ENUM_STAGE_TYPE.appointment_attended ||
			viewStageInfo?.type == ENUM_STAGE_TYPE.appointment_attended

		if (personDetails?.person?.id && fetchAllowed) {
			fetchLastDeposit({
				variables: { where: { origin: personDetails?.person?.id, type: 'deposit' } },
			})
		}
	}, [])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size='3xl' scrollBehavior='inside'>
				<ModalOverlay backdropBlur='xl' />
				<ModalContent as='form'>
					<ModalHeader>{editMode ? editStageTitle : moveStageTitle}</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={10}>
						{viewStageDetailsResult.loading ? (
							<Stack spacing={10} w='full' mt={10}>
								<Skeleton height='4em' rounded='md' />
								<Skeleton height='4em' rounded='md' />
								<Skeleton height='4em' rounded='md' />
							</Stack>
						) : (
							<Grid
								w='full'
								gap='2em'
								gridTemplateColumns={{
									sm: '1fr',
									md: 'repeat(1, 1fr)',
								}}
							>
								{['TX_given', 'TX_updated'].includes(stageType) && !txPlanId && (
									<Text>TX Plan ID should be set before we can move forward.</Text>
								)}

								{[
									'failed_to_convert',
									'consult_failed_to_book',
									'appointment_failed_to_attend',
									'oct_appointment_failed_to_attend',
								].includes(stageType) && (
									<GridItem
										bg='white'
										border='1px'
										borderColor='gray.200'
										shadow='md'
										p='1em'
										rounded='md'
									>
										<Box>
											<FailedReasonInput
												personData={personDetails?.person}
												stageId={viewStageDetailsResult.data?.stage?.id}
												editMode={editMode}
												failureTag={viewStageDetailsResult.data?.stage?.failure_tag}
												failureNotes={viewStageDetailsResult.data?.stage?.failure_notes}
												onAdd={data =>
													setStageFieldVals(prevVals => ({
														...prevVals,
														failure_tag: data.failure_tag,
														failure_notes: data.failure_notes,
													}))
												}
											/>
										</Box>
									</GridItem>
								)}
							</Grid>
						)}
					</ModalBody>
					<ModalFooter mt={10}>
						<Button onClick={onClose} mr={3} isDisabled={isSubmitting}>
							{editMode ? 'Close' : 'Cancel'}
						</Button>
						{!editMode && (
							<Button
								colorScheme='green'
								type='button'
								onClick={submitHandler}
								isLoading={isSubmitting}
								isDisabled={isSubmitting || personDetailsLoading || viewStageDetailsResult.loading}
							>
								Move
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default StageView
