import { useQuery, gql } from '@apollo/client'
import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'
import { editLeadInput } from '__generated__/globalTypes'
import { navigate } from 'gatsby'

import { FC } from 'react'

import { LeadFormLoader } from '~/components/lead/LeadFormNew'
import LeadFormNew from '~/components/lead/LeadFormNew'
import Button from '~/components/ui/Button'
import { useUpdateLead } from '~/crud/lead'

import { FindLead, FindLeadVariables } from './__generated__/FindLead'

/***
 *
 * Queries & Mutations
 *
 ***/
export const leadDetailsQuery = gql`
	query FindLead($id: ID!) {
		findLead(id: $id) {
			id
			inquiries {
				origin
				inquiries {
					id
				}
			}
			clinic {
				id
				name
				reception_email
				consultation_email
				cst_email
			}
			assignees {
				origin
				assignees {
					id
				}
			}
			alarm {
				id
				due_date
				due_time
			}
			attempts {
				id
			}
			tags {
				id
				text
			}
			current_stage {
				id
				type
				possible_next_stages
			}
			person {
				id
				first_name
				middle_name
				last_name
				email
				phone
				dentally_uuid
				dentally_person_id
			}
			notes(sort: "updated_at:desc") {
				id
				text
				type
				updated_at
				owner {
					id
					username
				}
			}
			consented {
				origin
				consented
			}
			preferred_contact_time
			preferred_contact_method
			preferred_contact_day
			has_unread_message
			unread_message_id
			archive
			treatment_plan_id
			treatment_plan_price
			deposit_paid
			payment_taken
			waiting_for_clin_check
			finance_application
			converted
			dentally_appointment_id
			tco_assignee {
				id
				username
			}
			dentist_assignee {
				id
				username
			}
			cst_assignee {
				id
				username
			}
		}
	}
`

/***
 *
 * Lead View Component
 *
 ***/
interface Props {
	leadId: string
}

const LeadDetailView: FC<Props> = ({ leadId }) => {
	const { data, loading, refetch } = useQuery<FindLead, FindLeadVariables>(leadDetailsQuery, {
		variables: { id: leadId },
	})

	const { handleLeadUpdate } = useUpdateLead()

	const refetchHandler = async () => {
		await refetch()
	}

	const onSubmitHandler = async (formData: editLeadInput) => {
		await handleLeadUpdate(leadId, formData)
	}

	if (loading) return <LeadFormLoader />

	if (!data?.findLead)
		return (
			<Alert
				status='error'
				variant='subtle'
				flexDirection='column'
				alignItems='center'
				justifyContent='center'
				textAlign='center'
				height='20em'
			>
				<AlertIcon boxSize='2em' mr={0} />
				<AlertTitle mt={4} mb={5} fontSize='2xl'>
					Error
				</AlertTitle>
				<AlertDescription fontSize='xl' maxWidth='sm'>
					Unable to load lead data.
				</AlertDescription>
				<Button mt={10} colorScheme='green' onClick={() => navigate(-1)}>
					Go Back
				</Button>
			</Alert>
		)

	return (
		<LeadFormNew
			mode='edit'
			leadDetails={data?.findLead}
			onSubmit={onSubmitHandler}
			onRefetch={refetchHandler}
			onCancel={() => {
				if (window.history.length < 2) {
					navigate(`/tco`)
				}

				return navigate(-1)
			}}
		/>
	)
}

export default LeadDetailView
